import React, { useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import { NavLink } from "react-router-dom";
import DataContent from "./DataContent";
import MetaData from "./MetaData";

export default function Home() {
  const Site_Url = "https://fathernamecorrection.com/";
  const BASE_URL = Site_Url + "admin/API/";

  //service API
  const [servicedata, setservicedata] = useState([]);
  useEffect(() => {
    const AllServices = async () => {
      const response = await fetch(`${BASE_URL}serviceAPI.php`);
      const jsonData = await response.json();
      setservicedata(jsonData);
    };
    AllServices();
  }, [BASE_URL]);

  //website Setting API
  const [webSetting, setwebSetting] = useState([]);
  useEffect(() => {
    const webSettingData = async () => {
      const response = await fetch(`${BASE_URL}website_settingAPI.php`);
      const jsonData = await response.json();
      setwebSetting(jsonData);
    };
    webSettingData();
  }, [BASE_URL]);

  //Testimonial API
  const [clientdata, setclientdata] = useState([]);
  useEffect(() => {
    const Allclient = async () => {
      const response = await fetch(`${BASE_URL}clientAPI.php`);
      const jsonData = await response.json();
      setclientdata(jsonData);
    };
    Allclient();
  }, [BASE_URL]);

  // Content Api
  const [contentdata, setcontentdata] = useState([]);
  useEffect(() => {
    const AllContent = async () => {
      const response = await fetch(`${BASE_URL}contentAPI.php`);
      const jsonData = await response.json();
      setcontentdata(jsonData);
    };
    AllContent();
  }, [BASE_URL]);

  //whychoose API
  const [whyChoosedata, setwhyChoosedata] = useState([]);
  useEffect(() => {
    const AllwhyChoose = async () => {
      const response = await fetch(`${BASE_URL}whychooseAPI.php`);
      const jsonData = await response.json();
      setwhyChoosedata(jsonData);
    };
    AllwhyChoose();
  }, [BASE_URL]);

  // Banner Api
  const [slider, setbannerdata] = useState([]);
  useEffect(() => {
    const AllBanner = async () => {
      const response = await fetch(`${BASE_URL}bannerAPI.php`);
      const jsonData = await response.json();
      setbannerdata(jsonData);
    };
    AllBanner();
  }, [BASE_URL]);

  // Blog API
  const [Blog, setBlogsdata] = useState([]);
  useEffect(() => {
    const AllBlogs = async () => {
      const response = await fetch(`${BASE_URL}homeblogAPI.php`);
      const jsonData = await response.json();
      setBlogsdata(jsonData);
    };
    AllBlogs();
  }, [BASE_URL]);

  const options = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    dots: true,
    loop: true,
    autoplay: true,
    navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      100: {
        items: 1,
      },
      200: {
        items: 2,
      },
      400: {
        items: 3,
      },
      600: {
        items: 4,
      },
      800: {
        items: 5,
      },
      1000: {
        items: 6,
      },
    },
  };

  const options1 = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    dots: true,
    loop: true,
    autoplay: true,
    navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  return (
    <>
      <MetaData />

      {/* Slider Start */}
      <section className="slider_sec pt-4 service_bg">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mt-3 mb-4">
              <h2 className="title text-light text-center">
                {webSetting.video_form_title}
              </h2>
            </div>

            <div className="col-lg-8 col-md-12">
              <div className="video_bx">
                <iframe
                  width="100%"
                  height="100%"
                  src={`https://www.youtube.com/embed/${webSetting.iframe_video}`}
                  title="Father Name Change Consultant in India | Fatherame Change Agent in India"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen=""
                ></iframe>
              </div>
            </div>

            <div className="col-lg-4 col-md-12">
              <div className="form_id">
                <iframe
                  width="100%"
                  height="450"
                  title="Father Name Change"
                  src="https://manage.yourdoorstep.co/forms/ticket"
                  frameBorder="0"
                  allowFullScreen
                  style={{ Border: `2px solid var(--bodyColor)`}}
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Slider End */}

      {/* Content-1 Section Start */}
      {contentdata.title1 && (
        <DataContent
          title={contentdata.title1}
          desc={contentdata.desciption1}
        />
      )}
      {/* Content-1 Section End */}

      {/* About Start */}
      <section className="mt-3">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="about_tx">
                <h3>{webSetting.about_title}</h3>
                <p>{webSetting.about_tagline}</p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: webSetting.about_desc,
                  }}
                ></p>
              </div>
            </div>

            <div className="col-md-6">
              <div
                id="carouselExampleControls"
                className="carousel slide"
                data-bs-ride="carousel"
              >
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img
                      src={slider.home_slider1}
                      className="d-block slide_bnr"
                      alt={slider.home_slider1_alt}
                      title={slider.home_slider1_title}
                    />
                  </div>

                  <div className="carousel-item">
                    <img
                      src={slider.home_slider2}
                      className="d-block slide_bnr"
                      alt={slider.home_slider2_alt}
                      title={slider.home_slider2_title}
                    />
                  </div>
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* About End*/}

      {/* Video And WhyChoose Start */}
      <section className="video_sec">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-6 mb-3">
              <div className="consult_bx">
                <img src={whyChoosedata.stap1_img} alt="" title="" />
                <div className="consult_bx_tx">
                  <p>{whyChoosedata.stap1_title}</p>
                  <h6>{whyChoosedata.stap1_tagline}</h6>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-6 mb-3">
              <div className="consult_bx">
                <img src={whyChoosedata.stap2_img} alt="" title="" />
                <div className="consult_bx_tx">
                  <p>{whyChoosedata.stap2_title}</p>
                  <h6>{whyChoosedata.stap2_tagline}</h6>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-6">
              <div className="consult_bx">
                <img src={whyChoosedata.stap3_img} alt="" title="" />
                <div className="consult_bx_tx">
                  <p>{whyChoosedata.stap3_title}</p>
                  <h6>{whyChoosedata.stap3_tagline}</h6>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-6">
              <div className="consult_bx">
                <img src={whyChoosedata.stap4_img} alt="" title="" />
                <div className="consult_bx_tx">
                  <p>{whyChoosedata.stap4_title}</p>
                  <h6>{whyChoosedata.stap4_tagline}</h6>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </section>
      {/* Video And WhyChoose Start */}

      {contentdata.title2 && (
        <DataContent
          title={contentdata.title2}
          desc={contentdata.desciption2}
        />
      )}

      {/*Service Start */}
      <section className="service_sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="service_title ">
                <h2>{webSetting.service_title}</h2>
              </div>

              <OwlCarousel className="owl-theme" {...options}>
                {servicedata.map((value, index) => (
                  <div key={index} className="item box_item service_icn">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: value.icon,
                      }}
                    ></div>
                    <div className="service_tx">
                      <a href="/service">{value.name}</a>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
      {/*Service End */}

      {contentdata.title3 && (
        <DataContent
          title={contentdata.title3}
          desc={contentdata.desciption3}
        />
      )}

      {/*Start How Work */}
      <section className="work_sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="work_tx">
                <h2>How It Works </h2>
                <p>
                  {" "}
                  We provide best name change consultant which provide reliable
                  solution for your name change in your legal document
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-2 col-6 mt-2">
              <div className="work_img">
                <i className="fa fa-hand-o-up" aria-hidden="true"></i>
                <h6>Select Name Change Consultant</h6>
              </div>
            </div>

            <div className="col-md-2 col-6 mt-2">
              <div className="work_img">
                <i className="fa fa-envelope-o" aria-hidden="true"></i>
                <h6>Send Inquiry</h6>
              </div>
            </div>

            <div className="col-md-2 col-6 mt-2">
              <div className="work_img">
                <i className="fa fa-question-circle-o" aria-hidden="true"></i>
                <h6>Ask Question About Name Change</h6>
              </div>
            </div>

            <div className="col-md-2 col-6 mt-2">
              <div className="work_img">
                <i className="fa fa-file-text" aria-hidden="true"></i>
                <h6>Send Document For Name correction</h6>
              </div>
            </div>

            <div className="col-md-2 col-6 mt-2">
              <div className="work_img">
                <i className="fa fa-credit-card" aria-hidden="true"></i>
                <h6>Pay Online</h6>
              </div>
            </div>

            <div className="col-md-2 col-6 mt-2">
              <div className="work_img">
                <i className="fa fa-hourglass-start" aria-hidden="true"></i>
                <h6>Work Start</h6>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*End How Work */}

      {contentdata.title4 && (
        <DataContent
          title={contentdata.title4}
          desc={contentdata.desciption4}
        />
      )}

      {/* Testimonials Start */}
      <section className="testimonial_sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="about_tx text-center">
                <h4>Testimonials </h4>
                <h2> We love our clients</h2>
              </div>

              <OwlCarousel className="owl-theme" {...options1}>
                {clientdata.map((value, index) => (
                  <div key={index} className="item client_review">
                    <img src={value.image} alt="" title="" />
                    <h5>{value.name}</h5>
                    <p className="text-center">{value.designation}</p>
                    <p>{value.description}</p>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
      {/* Testimonials Start */}

      {contentdata.title5 && (
        <DataContent
          title={contentdata.title5}
          desc={contentdata.desciption5}
        />
      )}

      {/* Blog Start */}
      <section className="blog_sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <div className="service_title blog_title">
                <h2>Blogs</h2>
              </div>
            </div>
          </div>

          <div className="row">
            {Blog.map((value, index) => (
              <div key={index} className="col-lg-4 col-md-6 col-sm-6 mb-3">
                <div className="blog_bx">
                  <NavLink
                    to={
                      value.lang === "en"
                        ? `/${value.slug}`
                        : `/${value.lang}/${value.slug}`
                    }
                  >
                    <img
                      src={value.sort_img}
                      alt={value.image_alt}
                      title={value.image_title}
                    />
                  </NavLink>
                  <div className="blog_tx">
                    <span>
                      <i className="fa fa-calendar" aria-hidden="true"></i>{" "}
                      {value.date}
                    </span>
                    <h5>
                      <NavLink
                        to={
                          value.lang === "en"
                            ? `/${value.slug}`
                            : `/${value.lang}/${value.slug}`
                        }
                      >
                        {value.title}
                      </NavLink>
                    </h5>
                    <p className="blog_tx_tg">{value.tagline}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* Blog End */}
    </>
  );
}
